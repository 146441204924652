import {
  PiWizardActionTypeEnum,
  PiWizardConfig,
  PiWizardFieldTypeEnum,
  PiWizardNavigationButtonEnum
} from "@/components/Wizard/WizardNew/types";
import { successNotification } from "@/includes/NotificationService";
import i18n from "@/i18n/i18n";
import store from "@/store/store";

export const chatWizardConfig: PiWizardConfig = {
    pages: {
      '1': {
        page: {
          layout: () => import('./components/PiBaseLayout.vue'),
          title: i18n.t('wizard_chat_screen_1_title', [ store.state.chatState.chat?.group_title ]).toString(),
          headerDescription: i18n.t('wizard_chat_screen_1_header_description').toString(),
          fields: [
            {
              type: PiWizardFieldTypeEnum.ControlButton,
              title: i18n.t('wizard_chat_screen_1_control_action_goto_2_title').toString(),
              antType: "primary",
              gotoPageKey: '2'
            },
            {
              type: PiWizardFieldTypeEnum.ControlButton,
              title: i18n.t('wizard_chat_screen_1_control_action_goto_2_1_title').toString(),
              antType: "primary",
              gotoPageKey: '2.1'
            },
          ],
        }
      },
      '2.1': {
        page: {
          layout: () => import('./components/PiBaseLayout.vue'),
          title: i18n.t('wizard_chat_screen_2_1_title').toString(),
          headerDescription: i18n.t('wizard_chat_screen_2_1_header_description', [ store.state.chatState.chat?.group_title ]).toString(),
          fields: [],
          navigation: [
            {
              type: PiWizardNavigationButtonEnum.BackButton,
              gotoPageKey: '1'
            },
            {
              type: PiWizardNavigationButtonEnum.CompleteButton,
              classNames: [ 'btn-success' ],
              title: i18n.t('wizard_chat_screen_2_1_navigation_button_complete').toString(),
              onComplete: (model, closeWizard) => {
                closeWizard()
                successNotification('WIZARD COMPLETE')
              }
            }
          ]
        }
      },
      '2': {
        page: {
          title: i18n.t('wizard_chat_screen_2_title').toString(),
          headerDescription: i18n.t('wizard_chat_screen_2_header_description').toString(),
          footerDescription: i18n.t('wizard_chat_screen_2_footer_description').toString(),
          layout: () => import('./components/PiBaseLayout.vue'),
          fields: [
            {
              type: PiWizardFieldTypeEnum.ManualCheckbox,
              initValue: model => !!model?.Greeting,
              checkboxStateActions: {
                true: 'action1',
                false: 'action2',
              },
              localFieldPath: {
                key: 'Greeting',
                prefix: 'chat_wizard_screen_2_checkbox_'
              }
            }
          ],
          navigation: [
            {
              type: PiWizardNavigationButtonEnum.BackButton,
              gotoPageKey: '1'
            },
            {
              type: PiWizardNavigationButtonEnum.NextButton,
              gotoPageKey: '3'
            }
          ],
        },
        actions: {
          'action1': {
            type: PiWizardActionTypeEnum.Set,
            item: {
              path: 'Greeting',
              value: {}
            }
          },
          'action2': {
            type: PiWizardActionTypeEnum.Unset,
            item: {
              path: 'Greeting'
            }
          },
        }
      },
      '3': {
        page: {
          title: i18n.t('wizard_chat_screen_3_title').toString(),
          footerDescription: i18n.t('wizard_chat_screen_3_footer_description').toString(),
          layout: () => import('./components/PiBaseLayout.vue'),
          fields: [
            {
              type: PiWizardFieldTypeEnum.ManualCheckbox,
              initValue: model => !!model?.Censor,
              checkboxStateActions: {
                true: 'CensorTrue',
                false: 'CensorFalse',
              },
              localFieldPath: {
                key: 'Censor',
                prefix: 'chat_wizard_screen_3_checkbox_'
              }
            },
            {
              type: PiWizardFieldTypeEnum.ManualCheckbox,
              initValue: model => !!model?.WebLinks,
              checkboxStateActions: {
                true: 'WebLinksTrue',
                false: 'WebLinksFalse',
              },
              localFieldPath: {
                key: 'WebLinks',
                prefix: 'chat_wizard_screen_3_checkbox_'
              }
            },
            {
              type: PiWizardFieldTypeEnum.ManualCheckbox,
              initValue: model => !!model?.SelfLinks,
              checkboxStateActions: {
                true: 'SelfLinksTrue',
                false: 'SelfLinksFalse',
              },
              localFieldPath: {
                key: 'SelfLinks',
                prefix: 'chat_wizard_screen_3_checkbox_'
              }
            },
            {
              type: PiWizardFieldTypeEnum.ManualCheckbox,
              initValue: model => !!model?.Reply,
              checkboxStateActions: {
                true: 'ReplyTrue',
                false: 'ReplyFalse',
              },
              localFieldPath: {
                key: 'Reply',
                prefix: 'chat_wizard_screen_3_checkbox_'
              }
            },
            {
              type: PiWizardFieldTypeEnum.ManualCheckbox,
              initValue: model => !!model?.VoiceMedia,
              checkboxStateActions: {
                true: 'VoiceMediaTrue',
                false: 'VoiceMediaFalse',
              },
              localFieldPath: {
                key: 'VoiceMedia',
                prefix: 'chat_wizard_screen_3_checkbox_'
              }
            }
          ],
          navigation: [
            {
              type: PiWizardNavigationButtonEnum.BackButton,
              gotoPageKey: '2'
            },
            {
              type: PiWizardNavigationButtonEnum.NextButton,
              gotoPageKey: '4'
            }
          ],
        },
        actions: {
          CensorTrue: {
            type: PiWizardActionTypeEnum.Set,
            item: {
              path: 'Censor',
              value: {}
            }
          },
          CensorFalse: {
            type: PiWizardActionTypeEnum.Unset,
            item: {
              path: 'Censor'
            }
          },
          WebLinksTrue: {
            type: PiWizardActionTypeEnum.Set,
            item: {
              path: 'WebLinks',
              value: {}
            }
          },
          WebLinksFalse: {
            type: PiWizardActionTypeEnum.Unset,
            item: {
              path: 'WebLinks'
            }
          },
          SelfLinksTrue: {
            type: PiWizardActionTypeEnum.Set,
            item: {
              path: 'SelfLinks',
              value: {}
            }
          },
          SelfLinksFalse: {
            type: PiWizardActionTypeEnum.Unset,
            item: {
              path: 'SelfLinks'
            }
          },
          ReplyTrue: {
            type: PiWizardActionTypeEnum.Set,
            item: {
              path: 'Reply',
              value: {}
            }
          },
          ReplyFalse: {
            type: PiWizardActionTypeEnum.Unset,
            item: {
              path: 'Reply'
            }
          },
          VoiceMediaTrue: {
            type: PiWizardActionTypeEnum.Set,
            item: {
              path: 'VoiceMedia',
              value: {}
            }
          },
          VoiceMediaFalse: {
            type: PiWizardActionTypeEnum.Unset,
            item: {
              path: 'VoiceMedia'
            }
          }
        },
      },
      '4': {
        page: {
          title: i18n.t('wizard_chat_screen_4_title').toString(),
          headerDescription: i18n.t('wizard_chat_screen_4_header_description').toString(),
          footerDescription: i18n.t('wizard_chat_screen_4_footer_description').toString(),
          condition: "condition1",
          layout: () => import('./components/PiBaseLayout.vue'),
          fields: [
            {
              type: PiWizardFieldTypeEnum.ManualCheckbox,
              initValue: model => !!model?.Reputation,
              checkboxStateActions: {
                true: 'ReputationTrue',
                false: 'ReputationFalse',
              },
              localFieldPath: {
                key: 'Reputation',
                prefix: 'chat_wizard_screen_4_checkbox_'
              }
            },
            {
              type: PiWizardFieldTypeEnum.ManualCheckbox,
              initValue: model => !!model?.Report,
              checkboxStateActions: {
                true: 'ReportTrue',
                false: 'ReportFalse',
              },
              localFieldPath: {
                key: 'Report',
                prefix: 'chat_wizard_screen_4_checkbox_'
              }
            },
            {
              type: PiWizardFieldTypeEnum.ManualCheckbox,
              initValue: model => !!model?.Warn,
              checkboxStateActions: {
                true: 'WarnTrue',
                false: 'WarnFalse',
              },
              localFieldPath: {
                key: 'Warn',
                prefix: 'chat_wizard_screen_4_checkbox_'
              }
            },
          ],
          navigation: [
            {
              type: PiWizardNavigationButtonEnum.BackButton,
              gotoPageKey: '3',
            },
            {
              type: PiWizardNavigationButtonEnum.NextButton,
              gotoPageKey: '5'
            }
          ],
        },
        actions: {
          ReputationTrue: {
            type: PiWizardActionTypeEnum.Set,
            item: {
              path: 'Reputation',
              value: {}
            }
          },
          ReputationFalse: {
            type: PiWizardActionTypeEnum.Unset,
            item: {
              path: 'Reputation'
            }
          },
          ReportTrue: {
            type: PiWizardActionTypeEnum.Set,
            item: {
              path: 'Report',
              value: {}
            }
          },
          ReportFalse: {
            type: PiWizardActionTypeEnum.Unset,
            item: {
              path: 'Report'
            }
          },
          WarnTrue: {
            type: PiWizardActionTypeEnum.Set,
            item: {
              path: 'Warn',
              value: {}
            }
          },
          WarnFalse: {
            type: PiWizardActionTypeEnum.Unset,
            item: {
              path: 'Warn'
            }
          }
        },
      },
      '5': {
        page: {
          layout: () => import('./components/PiBaseLayout.vue'),
          title: i18n.t('wizard_chat_screen_5_title').toString(),
          headerDescription: i18n.t('wizard_chat_screen_5_header_description').toString(),
          footerDescription: i18n.t('wizard_chat_screen_5_footer_description').toString(),
          fields: [
            {
              type: PiWizardFieldTypeEnum.ManualCheckbox,
              initValue: model => !!model?.Instruction,
              checkboxStateActions: {
                true: 'InstructionTrue',
                false: 'InstructionFalse',
              },
              localFieldPath: {
                key: 'Instruction',
                prefix: 'chat_wizard_screen_5_checkbox_'
              }
            },
          ],
          navigation: [
            {
              type: PiWizardNavigationButtonEnum.BackButton,
              gotoPageKey: '4'
            },
            {
              type: PiWizardNavigationButtonEnum.NextButton,
              gotoPageKey: '6'
            }
          ]
        },
        actions: {
          InstructionTrue: {
            type: PiWizardActionTypeEnum.Set,
            item: {
              path: 'Instruction',
              value: {}
            }
          },
          InstructionFalse: {
            type: PiWizardActionTypeEnum.Unset,
            item: {
              path: 'Instruction'
            }
          }
        },
      },
      '6': {
        page: {
          layout: () => import('./components/PiBaseLayout.vue'),
          title: i18n.t('wizard_chat_screen_6_title', [ store.state.chatState.chat?.group_title ]).toString(),
          headerDescription: i18n.t('wizard_chat_screen_6_header_description', [ store.state.chatState.chat?.group_title ]).toString(),
          navigation: [
            {
              type: PiWizardNavigationButtonEnum.CompleteButton,
              classNames: [ 'btn-success' ],
              title: i18n.t('wizard_chat_screen_6_navigation_button_complete').toString(),
              onComplete: (model, closeWizard) => {
                closeWizard()
                successNotification('WIZARD COMPLETE')
              }
            }
          ],
        }
      }
    }
  }
