import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

export enum PiWizardFieldTypeEnum {
  /* manual fields */
  ManualCheckbox = 'ManualCheckbox',
  ManualChips = 'ManualChips',

  /************/
  ControlButton = 'ControlButton'
}

export const enum PiWizardNavigationButtonEnum {
  BackButton = 'BackButton',
  NextButton = 'NextButton',
  CompleteButton = 'CompleteButton'
}

export const enum PiWizardActionTypeEnum {
  Merge = 'Merge',
  Set = 'Set',
  Unset = 'Unset',
}

export const enum PiWizardConditionTypeEnum {
  Empty = 'Empty',
  NotEmpty = 'NotEmpty'
}

export type ButtonFields = {
  title?: string
  icon?: string
  antType?: 'danger' | 'primary' | 'link'
  classNames?: Array<string>
}

export type NavigationButtonFields = {
  gotoPageKey: string
}

export type CustomButtonClick = (model: any, closeWizard: () => void) => void

export type BackButton = {
  type: PiWizardNavigationButtonEnum.BackButton
} & ButtonFields & NavigationButtonFields

export type NextButton = {
  type: PiWizardNavigationButtonEnum.NextButton
} & ButtonFields & NavigationButtonFields

export type CompleteButton = {
  type: PiWizardNavigationButtonEnum.CompleteButton
  onComplete: CustomButtonClick
} & ButtonFields

export type WizardNavigationButton = BackButton | NextButton | CompleteButton

export type PiWizardConfig = {
  pages: Record<string, PiWizardStep>
}

export type PiWizardPage = {
  title?: string
  condition?: string
  headerDescription?: string
  footerDescription?: string
  layout: VueComponent
  fields?: Array<PiWizardField>
  navigation?: PiWizardNavigation
}

export type PiWizardStep = {
  page: PiWizardPage
  actions?: Record<string, PiWizardActions>
  conditions?: Record<string, Array<Array<PiWizardConditions>>>
}

export type PiWizardConditions = {
  type: PiWizardConditionTypeEnum
  fieldPath: string
}

export type PiWizardActions = MergeAction | SetAction | UnsetAction

export type MergeAction = {
  type: PiWizardActionTypeEnum.Merge,
  item: any
}

export type SetAction = {
  type: PiWizardActionTypeEnum.Set,
  item: {
    path: string,
    value: any
  }
}

export type UnsetAction = {
  type: PiWizardActionTypeEnum.Unset,
  item: {
    path: string
  }
}

export type ControlButton = {
  type: PiWizardFieldTypeEnum.ControlButton
  action?: PiWizardActions
  onClick?: CustomButtonClick
} & ButtonFields & Partial<NavigationButtonFields>

export type PiWizardFieldPath = Partial<{
  model: string
  key: string
  prefix?: string
}>

export type ManualControlFields = {
  localFieldPath: PiWizardFieldPath
  initValue: (model: any) => boolean
}

export type ManualCheckbox = {
  type: PiWizardFieldTypeEnum.ManualCheckbox
  checkboxStateActions: {
    'true': string,
    'false': string
  }
} & ManualControlFields

export type ManualChips = {
  type: PiWizardFieldTypeEnum.ManualChips
} & ManualControlFields

export type PiWizardField = ControlButton | ManualCheckbox | ManualChips

export type PiWizardNavigation = Array<WizardNavigationButton>
